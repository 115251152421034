<template>
  <div v-if="isStaff" class="box has-background-grey-dark">
    <div class="title-wrap">
      <h1 class="title mb-1">Drifts</h1>
    </div>
    <Timestamp :timestamp-data="timeData"/>

    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Cusip</th>
          <th class="hiddencolumn" scope="col">Exchange Name</th>
          <th class="hiddencolumn" scope="col">Risk Live</th>
          <th scope="col">Risk Dict</th>
          <th scope="col">Mid Price</th>
          <th class="hiddencolumn" scope="col">Mid Price Dict</th>
          <th scope="col">Mid Price Drift</th>
          <th class="hiddencolumn" scope="col">YYield Dict</th>
          <th scope="col">Yield Live</th>
          <th scope="col">Yield Drift</th>
          <th scope="col">Last Delivery</th>
          <th scope="col">&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-if="timeData === 'Loading...'" colspan="9">
            <div class="container-load">
              <i class="fas fa-circle-notch fa-spin fa-5x"></i>
            </div>
          </td>
        </tr>
        <tr v-if="showEdit" class="list list-edit">
          <td class="has-background-warning" >
            <div class="field has-addons">
              <label class="label" for="product_name"></label>
              <div class="control">
                <input v-model="product_name"  readonly disabled class="input is-disabled" type="text" name="product_name"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning" >
            <div class="field has-addons">
              <label class="label" for="cusip"></label>
              <div class="control">
                <input v-model="cusip"  readonly disabled class="input is-disabled" type="text" name="cusip"/>
              </div>
            </div>
          </td>
          <td id="hidden" class="has-background-warning" >
            <div class="field has-addons">
              <label class="label" for="exchangeName"></label>
              <div class="control">
                <input v-model="exchangeName"  readonly disabled class="input is-disabled" type="text" name="exchangeName"/>
              </div>
            </div>
          </td>

          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="risk"></label>
              <div class="control">
                <input v-model="risk" readonly disabled class="input is-disabled bg-primary-grey" type="text" name="risk"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="riskDict"></label>
              <div class="control">
                <input v-model="riskDict" readonly disabled class="input is-disabled bg-primary-grey" type="text" name="riskDict"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="midPrice"></label>
              <div class="control">
                <input v-model="midPrice" class="input" type="text" name="midPrice"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="midPriceDict"></label>
              <div class="control">
                <input v-model="midPriceDict" readonly disabled class="input is-disabled bg-primary-grey" type="text"
                       name="midPriceDict"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="midPriceDrift"></label>
              <div class="control">
                <input v-model="midPriceDrift" readonly disabled class="input is-disabled" type="text" name="midPriceDrift"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="yyieldDict"></label>
              <div class="control">
                <input v-model="yyieldDict" readonly disabled class="input is-disabled" type="text" name="yyieldDict"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="yyieldLive"></label>
              <div class="control">
                <input v-model="yyieldLive" class="input" type="text" name="yyieldLive"/>
              </div>
            </div>
          </td>
          <td class="has-background-warning">
            <div class="field has-addons">
              <label class="label" for="yyieldDrift"></label>
              <div class="control has-icons-right">
                <input v-model="yyieldDrift" readonly disabled class="input is-disabled" type="text" name="yyieldDrift"/>
              </div>
            </div>
          </td>
            <td class="has-background-warning">
                <div class="field has-addons">
                    <label class="label" for="last_delivery"></label>
                    <div class="control has-icons-right">
                        <input v-model="last_delivery" readonly disabled class="input is-disabled" type="text" name="last_delivery"/>
                    </div>
                </div>
            </td>
          <td class="has-background-warning">
            <div class="field">
              <div class="control" style="display:inline-flex">
                <a class="button is-info mr-2" @click="onUpdate()">
                    <span class="icon is-left">
                      <i class="fas fa-check fa-sm"></i>
                    </span>
                </a>
                <a class="button is-danger" @click="showEdit = !showEdit">
                    <span class="icon is-right">
                      <i class="fas fa-times fa-sm"></i>
                    </span>
                </a>
              </div>
            </div>
          </td>
        </tr>

        <tr v-for="entry in gridData" :key="entry['Strategy']" class="list" :class="{ disabled: entry['Exchange Name'] == null }"
            @click="showUpdateForm(entry)">

          <td v-for="key in gridColumns" :key="key"
              :class="{disabled: (key=='YYieldDrift' && entry['YYieldDrift'] == 'NaN'), strat_down: (entry['a_status'] == 'a_down'),
            inc_a : (entry['a_status'] == 'a_up' && key == 'Change'),
            dec_a: (entry['a_status'] == 'a_down'), yyielddrift: key == 'YYieldDrift',  }"
              :data-tooltip="entry['strat_legs']"
          >
            {{
              (typeof entry[key] === 'number' && key !== 'YYield Dict' && key !== 'YYield live' && key !== 'YYieldDrift' && key !== 'Mid Price Drift') ? entry[key].toFixed(4) : null
            }}
            {{
              (typeof entry[key] === 'number' && (key === 'YYield Dict' || key === 'YYield live')) ? entry[key].toFixed(4) : null
            }}
            {{ (typeof entry[key] === 'number' && key === 'YYieldDrift') ? entry[key].toFixed(3) : null }}
            {{ (typeof entry[key] === 'number' && key === 'Mid Price Drift') ? entry[key].toFixed(0) : null }}
            {{ typeof entry[key] === 'string' ? entry[key] : null }}
            <button v-if="checkDrift(entry,key)" class="arrow"/>
          </td>
          <td>
            <div class="button">
              <span class="icon is-right">
                <i class="fas fa-pen fa-sm"></i>
              </span>
            </div>
          </td>
        </tr>

        </tbody>
      </table>
      <div class="container">
        <div class="button"  @click="onUpdateAll(gridData)">
          <p>Update All</p>
          <span class="icon is-right">
                <i class="fas fa-check fa-sm"></i>
              </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ref, reactive, toRefs} from 'vue';
import useNotyf from '../../composable/useNotyf'
import store, {ActionTypes} from "../../store";
import {mapState, mapGetters, mapActions} from 'vuex'
import Timestamp from '../../components/shared/Timestamp.vue';
import {io} from "socket.io-client";
import instance from "../../services/fetch";

const socket = io('https://draxjb.com/', {path: '/wss'});

const notif = useNotyf()
export default {
  components: {
    Timestamp
  },
  setup() {
    const gData = reactive({
      product_name: '',
      cusip: '',
      exchangeName: '',
      risk: 0,
      riskDict: 0,
      midPrice: 0,
      midPriceDict: 0,
      midPriceDrift: 0,
      yyieldDict: 0,
      last_delivery: 0,
      yyieldLive: 0,
      yyieldDrift: 0,
    });

    let showEdit = ref(false);

    const showUpdateForm = (entry) => {
      showEdit.value = true;
      gData.product_name = entry['Name'];
      gData.cusip = entry['Bond_cusip'];
      gData.exchangeName = entry['Exchange Name'];
      gData.risk = entry['Risk'];
      gData.riskDict = entry['Risk Dict'];
      gData.midPrice = entry['Mid Price'];
      gData.midPriceDict = entry['Mid Price Dict'];
      gData.midPriceDrift = entry['Mid Price Drift'];
      gData.yyieldDict = entry['YYield Dict'];
      gData.yyieldLive = entry['YYield live'];
      gData.yyieldDrift = entry['YYieldDrift'];
      gData.last_delivery = entry['Last Delivery'];
    }



    const onUpdate = () => {
      store.dispatch(ActionTypes.POST_DRIFT, gData).then(() => {
        notif.info(gData.exchangeName + ' Updated!!')
      });

      showEdit.value = false;
    }

    return {
      showEdit,
      showUpdateForm,
      ...toRefs(gData),
      onUpdate,
    }
  },
  data() {
    return {
      gridColumns: ['Name', 'Bond_cusip', 'Exchange Name', 'Risk', 'Risk Dict' ,'Mid Price', 'Mid Price Dict', 'Mid Price Drift', 'YYield Dict', 'YYield live', 'YYieldDrift', 'Last Delivery'],
      gridData: Array,
      timeData: 'Loading...',
      player:4
    }
  },
  created() {

  },
  computed: {
    ...mapState(['user']),
    ...mapGetters([
      'isStaff'
    ])
  },
  sockets: {
    disconnect() {
    },
    connect() {
    },
    getdrift(val) {
      if (val.user_id == 16) {
        this.gridData = JSON.parse(val.getdrift)
        this.timeData = val.lastupdated
      }
    }

  },

  mounted() {
    socket.disconnect()
    socket.connect()
    socket.on('getdrift', (data) => {
      if (data.user_id == 16) {
        this.gridData = JSON.parse(data.getdrift)
        this.timeData = data.lastupdated
      }

    }),
        window.setInterval(() => {
          socket.emit('get_drift', this.user.id);
        }, 900)
  },

  methods: {
    checkDrift(entry, key) {

      if (entry['a_status'] == 'a_down' && key == 'YYieldDrift' && this.player < 2) {
        notif.warning('Drift ' + entry['Exchange Name'])
        return true

      }

    },
    updateDb(gData) {

      if (gData.cusip) {
        notif.info(gData.product_name + ' Updated!!')
        const options = {
          method: 'post',
          url: 'drift_update/',
          data: {
            "product_name": gData.product_name,
            "cusip": gData.cusip,
            "exchangeName": gData.exchangeName,
            "risk": gData.risk,
            "riskDict": gData.riskDict,
            "midPrice": gData.midPrice,
            "midPriceDict": gData.midPriceDict,
            "midPriceDrift": gData.midPriceDrift,
            "yyieldDict": gData.yyieldDict,
            "yyieldLive": gData.yyieldLive,
            "yyieldDrift": gData.yyieldDrift,
          },
        }
        instance(options)

      }
    },
    onUpdateAll(gridData) {
      let gData = []
      gridData.forEach((entry) => {
            if (entry['Bond_cusip']) {
              gData.product_name = entry['Name'];
              gData.cusip = entry['Bond_cusip'];
              gData.exchangeName = entry['Exchange Name'];
              gData.risk = entry['Risk'];
              gData.riskDict = entry['Risk Dict'];
              gData.midPrice = entry['Mid Price'];
              gData.midPriceDict = entry['Mid Price Dict'];
              gData.midPriceDrift = entry['Mid Price Drift'];
              gData.yyieldDict = entry['YYield Dict'];
              gData.yyieldLive = entry['YYield live'];
              gData.yyieldDrift = entry['YYieldDrift'];
              this.updateDb(gData)
            }
          },
      );
      //this.onUpdate()
    },
  },
  beforeUnmount() {
    socket.disconnect()
  }
}
</script>

<style lang="scss">

.currenttime {
  padding: 10px;
  font-weight: 900;
}

table {
  border-radius: 3px;

  .hiddencolumn {
    display: none;

  }

  td:nth-child(3) {
    display: none;
  }

  td:nth-child(4) {
    display: none;
  }

  td:nth-child(7) {
    display: none;
  }

  td:nth-child(9) {
    display: none;
  }
}


.list.disabled {
  display: none;
}

@media (max-width: 767px) {
  .view-wrapper {
    width: 100% !important;
    margin-left: 0 !important;
    // margin-top: 10px !important;
  }
}

@media screen and (max-width: 992px) {
  table {
    overflow-x: auto;
    /* line-height: 0.65; */
  }

}

.yyielddrift {
  color: #8aaeff !important;
  font-weight: bold;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.strat_up {
  -webkit-animation-name: flash;
  -webkit-animation-duration: 7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.strat_down {
  -webkit-animation-name: flash2;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

@-webkit-keyframes flash {
  from {
    color: #ffffff;
    font-weight: bold;
  }

  to {
    color: #a7a7a7;
    font-weight: bold;
  }

}

@-webkit-keyframes flash2 {
  from {
    color: #ffffff;
    font-weight: bold;
  }

  to {
    color: #a7a7a7;
    font-weight: bold;
  }

}

th, td {
  padding: 0.4em 0.2em !important;
  text-align: right !important
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

#gridContainer span.current-value {
  display: inline-block;
  margin-right: 5px;
}

#gridContainer span.diff {
  width: 50px;
  display: inline-block;
}

.inc_a span {
  color: #81d76e !important;
  text-align: right !important;
}

.inc_a {
  color: #ababab;
  font-weight: bold;
}

.dec_a {
  color: #ff0000;
  font-weight: bold;
}

.dec_a span {
  color: #db5f5f;
  text-align: right !important;

}

.close {
  color: #f8e12f;
  text-align: right !important
}

.strat {
  text-align: left !important;
  padding-left: 6px !important;
}

.strat_r {
  text-align: right !important;
}

.price {
  text-align: left !important;
  padding-left: 47px !important;
}

.change {
  padding-left: 24px !important;
}

.inc_a .arrow,
.dec_a .arrow {
  display: inline-block;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.inc_a .arrow {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

.dec_a .arrow {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}

.container-load {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: #fff;
  }
}

.list-edit {
  input {
    font-size: 1.1rem;

    &.is-disabled {
      opacity: 0.6;
    }

    &[name="exchangeName"] {
      font-size: 1.5rem;
      font-weight: 900;
    }
  }
}
</style>
